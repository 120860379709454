.Clients {
    width: 100%;
    padding: 6rem 1rem;
    background-color: #000;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
    -webkit-user-drag: auto;
}

.card-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(1, 1fr);
}

.card-container .card {
    border: 1px solid #eee;
    color: #eee;
}

.card {
    text-align: center;
    padding: 1rem;
}

.card:hover {
    background-color: #141414;
}

.card h3 {
    font-size: 1.4rem;
    padding: 1rem;
}

.card .bar {
    border-bottom: 1px solid #eee;
    width: 10%;
    margin: 1.5rem auto;
    display: block;
}

.card .btc {
    font-size: 4rem;
    font-weight: 600;
    padding: 1rem;
}

.Clients p {
    color: #eee;
    padding: 1rem 0;
    font-size: 1.2rem;
}

.card .btn {
    display: block;
    width: 80%;
    margin: 2rem auto;
}

@media screen and (max-width: 980px) {
    .card-container {
        max-width: 100%;
        margin: auto;
        grid-template-columns: repeat(4, 1fr);
    }
}
.right2 .img {
  width: 145px;
    border: 1px solid #333;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
}

.right2 .img:hover {
    width: 145px;
      border: 1px solid #333;
      margin: auto;
      align-items: center;
      text-align: center;
      justify-content: center;
      line-height: normal;
      vertical-align: middle;
      transform: scale(2.0);
  }
  
  



.right2 .img1 {
    width: 145px;
    border: 1px solid #333;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
}
.right2 .img2 {
    width: 145px;
    border: 1px solid #333;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
}

.right2 .img-container {
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    text-align: center;
    justify-content: center;
      display: grid;
      grid-gap: 0.5rem;
      line-height: normal;
      vertical-align: middle;
      /* height: 150px; */
      /* align-items: center; */
      padding: 34px;
      top: 0;
      bottom: 0;
      left: 270;
      right: 0;
      margin-left: 230px;
      place-content: flex-end;
      margin-block: auto;
}







