@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Outfit:wght@100;300;400;500;600;700;800&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,h4,p,a {
  color: #fff;
}

body {
  margin: 0;
  font-family: 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000;
}
ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  padding: 12px 32px;
  font-size: 1.2rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}

/*.btn-light {
  background: rgba(255,255,255, .2);
}*/

.btn:hover {
  background: rgba(255,255,255, .2);
}
.footer {
    width: 100%;
    padding: 6rem 0;
    background: rgba(0,0,0,.8);
}

.footer-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    /*grid-template-columns: repeat(2, 1fr);*/
    grid-gap: 40px;
    text-align: center;
}

.h4 {
    text-decoration: underline;
    color: aquamarine;
    opacity: 1.0;
    font-size: 100px;
    color: rgba(0, 0, 0, 0.552);
    text-shadow: 4px 4px rgb(238, 232, 232);
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    text-transform: uppercase;
    transition: 0.5s;
}


.res{
    color: aquamarine;
    opacity: 1.0;
    font-size: 100px;
    color: rgba(0, 0, 0, 0.552);
    text-shadow: 4px 4px rgb(238, 232, 232);
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    text-transform: uppercase;
    transition: 0.5s;
}

.res:hover {
    letter-spacing: 10px;
}

.company {
    opacity: 1.0;
    font-size: 100px;
    color: rgba(0, 0, 0, 0.552);
    text-shadow: 4px 4px rgb(238, 232, 232);
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    text-transform: uppercase;
    transition: 0.5s;
}
.company:hover {
    letter-spacing: 10px;
}



.footer .left {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.footer .right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
}

.footer h4 {
    font-size: 1.4rem;
    padding: 1rem 0;
}

.footer p {
    font-size: 1.2rem;
    padding: 1rem 0;
}

.footer .location {
    display: flex;
    align-items: center;
}

.footer .location p {
    padding-bottom: .5rem;
}

.footer .location h4 {
    padding-top: 0;
}

@media screen and (max-width:640px) {
    .footer-container {
        grid-template-columns: 1fr;
    }

    .right .social {
        margin: auto;
    }
}








.header {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
    position: absolute;
}


.header-bg {
    background-color: rgba(0,0,0, .85);
    transition: .5s;
}

.nav-menu {
    display: flex;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu a {
    font-size: 1.2rem;
    font-weight: 500;
}

.nav-menu a:hover {
    color: lightgrey;
    text-decoration: underline; 
}

.hamburger {
    display: none;
}

@media screen and (max-width:1240px) {
    .hamburger {
        display: block;
    }

    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.3s;
        z-index: -1;
        background: rgba(0,0,0, .9);
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding: 1rem 0;
    }

    .nav-menu a {
        font-size: 2rem;
    }

    .logo {
        width: 30%;
        height: 30px;
    }
    
}









#video {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

/* #video2 {
    width: 590px;
    height: 190px;
    object-fit: cover;
    place-self: normal;
    font-size: xxx-large;
    animation: cssAnimation 6s forwards;
    margin-top: -170px;
} */


/* @keyframes cssAnimation {
    0%   {opacity: 1;}
    90%  {opacity: 1;}
    100% {opacity: 0;}
} */
/* @-webkit-keyframes cssAnimation {
    0%   {opacity: 1;}
    90%  {opacity: 1;}
    100% {opacity: 0;}
} */


.hero {
    height: 100vh;
    width: 100%;
    object-fit: contain;
}



.hero .content {
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 40vh;
}

.hero .content h1 {
    font-size: 4rem;
    color: #fff;
}

.hero p {
    font-size: 1.8rem;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    color: #fff;
}

.hero .btn {
    margin: 1rem .2rem;
}

@media screen and (max-width:640px) {
    .content h1 {
        font-size: 3rem;
    }

    .content p {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
    }
}












.Clients {
    width: 100%;
    padding: 6rem 1rem;
    background-color: #000;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
    -webkit-user-drag: auto;
}

.card-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(1, 1fr);
}

.card-container .card {
    border: 1px solid #eee;
    color: #eee;
}

.card {
    text-align: center;
    padding: 1rem;
}

.card:hover {
    background-color: #141414;
}

.card h3 {
    font-size: 1.4rem;
    padding: 1rem;
}

.card .bar {
    border-bottom: 1px solid #eee;
    width: 10%;
    margin: 1.5rem auto;
    display: block;
}

.card .btc {
    font-size: 4rem;
    font-weight: 600;
    padding: 1rem;
}

.Clients p {
    color: #eee;
    padding: 1rem 0;
    font-size: 1.2rem;
}

.card .btn {
    display: block;
    width: 80%;
    margin: 2rem auto;
}

@media screen and (max-width: 980px) {
    .card-container {
        max-width: 100%;
        margin: auto;
        grid-template-columns: repeat(4, 1fr);
    }
}
.right2 .img {
  width: 145px;
    border: 1px solid #333;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
}

.right2 .img:hover {
    width: 145px;
      border: 1px solid #333;
      margin: auto;
      align-items: center;
      text-align: center;
      justify-content: center;
      line-height: normal;
      vertical-align: middle;
      transform: scale(2.0);
  }
  
  



.right2 .img1 {
    width: 145px;
    border: 1px solid #333;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
}
.right2 .img2 {
    width: 145px;
    border: 1px solid #333;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
}

.right2 .img-container {
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    text-align: center;
    justify-content: center;
      display: grid;
      grid-gap: 0.5rem;
      line-height: normal;
      vertical-align: middle;
      /* height: 150px; */
      /* align-items: center; */
      padding: 34px;
      top: 0;
      bottom: 0;
      left: 270;
      right: 0;
      margin-left: 230px;
      place-content: flex-end;
      margin-block: auto;
}








.hero-img {
    background: rgba(0,0,0,.3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.hero-img:before {
    content:'';
    background: url(../../static/media/CodingImg.39a6cc2b.png) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero-img .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img h1 {
    font-size: 2.4rem;
}

.hero-img p {
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width:640px) {
    .hero-img h1 {
        font-size: 2rem;
    }
}
.training {
    width: 100%;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: 100%;
    text-align: center;
     justify-content: center;
    line-height: normal;
    vertical-align: middle;
    /* height: 150px; */
    /* align-items: center; */
    padding: 34px;
    grid-gap: 3rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.training .left {
    text-align: center;
    margin: auto;
    padding: 1rem 1rem;
    max-width: 600px;
    font-size: 11px;
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.984);
}



.btn{
    max-width: 466px;
    border-bottom: 1px dotted rgba(247, 242, 242, 0.677);
    white-space: pre;
}

.btn:before{
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 140px;
    background-color: rgba(0, 0, 0, 0.471);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
    left: 0;
    top: 110%;
}

.btn:hover:before{
    opacity: 1;
    visibility: visible;
    font-size: 12px;
    color: rgb(227, 109, 66);
    text-transform: uppercase;
}

.training .left p {
    margin: 1.2rem 0;
}

.training .right {
    width: 100%;
}

.right .img-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .img {
    max-width: 45%;
    border: 1px solid #333;
}
.right .img1 {
    max-width: 45%;
    border: 1px solid #333;
}
.right .img2 {
    max-width: 45%;
    border: 1px solid #333;
}
.right .img3 {
    max-width: 45%;
    border: 1px solid #333;
}

.right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.right .bottom {
    grid-column: 4/ -1; 
    grid-row: 1;
    z-index: 5;
}
.left .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.left .bottom {
    grid-column: 4/ -1; 
    grid-row: 1;
    z-index: 5;
}

@media screen and (max-width: 1140px) {
    .training {
        grid-template-columns: 1fr;
    }

    .training .img {
        max-width: 80%;
    }

}






.chip {
    font-size: .7rem;
    background: linear-gradient(to right, #6190e8, #a7bfe8);
    color: #fff;
    padding: .3rem .5rem;
    border-radius: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: capitalize;
    margin: auto;
}
.blogList-wrap {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 3rem;
    font: small-caption;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
    /* height: 150px; */
    /* align-items: center; */
    /*padding: 34px;*/
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align:center;
}

@media(max-width:768px){
    .blogList-wrap {
        grid-template-columns: repeat(2,1fr);
    }
}

@media(max-width:600px){
    .blogList-wrap {
        grid-template-columns: repeat(1,1fr);
    }
}
.searchBar-wrap{
    background-color: #f0f0f0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 2.5rem auto 4rem auto;
    padding: .5rem;
    border-radius: 5px;
    visibility: hidden;
}

.searchBar-wrap from{
    display: flex;
    align-items: center;
}

.searchBar-wrap input{
    background-color: #f0f0f0;
    outline: none;
    border: none;
}

.searchBar-wrap span{
    padding-right: .5rem;
    cursor: pointer;
}

.searchBar-wrap button{
    outline: none;
    border: none;
    padding: .3rem 1rem;
    border-radius: 5px;
    background-color: #0f52ba;
    color: #fff;
}
.emptyList-wrap{
    display: flex;
    justify-content: center;
}

.emptyList-wrap img{
    max-width: 250px;
    width: 100%;
}
.ProductList-wrap {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 3rem;
    font: small-caption;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
    /* height: 150px; */
    /* align-items: center; */
    /*padding: 34px;*/
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align:center;
}

@media(max-width:768px){
    .ProductList-wrap {
        grid-template-columns: repeat(2,1fr);
    }
}

@media(max-width:600px){
    .ProductList-wrap {
        grid-template-columns: repeat(1,1fr);
    }
}
.blog-wrap {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }
  
  .blog-goBack {
    text-decoration: none;
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
    margin-bottom: 2rem;
    display: block;
    cursor: pointer;
    font-size: large;
  }

  .blog-goBack:hover {
   color: lightblue;
  }

  .blog-wrap header {
    text-align: center;
  }
  
  .blog-date {
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
  }
  
  .blog-wrap img {
    width: 100%;
  }
  
  .blog-subCategory {
    display: flex;
    justify-content: center;
  }
  .blog-subCategory > div {
    margin: 1rem;
  }
  .blog-desc {
    padding: 1rem;
    margin-top: 1.5rem;
    font-size: large;
  }
 
  .Cover{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: larger;
  }
  .column {
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  
 

label {
    color: #eee;
}

form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
    max-width: 600px;
}

.Logo{
    
    /*inline-size: -webkit-fill-available;*/
    width: 30%;
    height: auto;
    font-size: 1.2rem;
    opacity: 0.5;
}

.h3 {
  text-decoration: underline;
}

form label {
    margin-bottom: .5rem;
}

form input, textarea {
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    font-family: 'Outfit', sans-serif;
    background: rgba(255,255,255,.1);
    border-color: rgba(255,255,255,.1);
    color: #f4f4f4;
}

.container {
    position: relative;
    text-align: center;
    color: white;
  }
  
  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
  
  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  
  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
.autoCAD-wrap {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }
  
  .autoCAD-goBack {
    text-decoration: none;
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
    margin-bottom: 2rem;
    display: block;
    cursor: pointer;
    font-size: large;
  }

  .autoCAD-goBack:hover {
   color: lightblue;
  }

  .autoCAD-wrap header {
    text-align: center;
  }
  
  .autoCAD-date {
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
  }
  
  .autoCAD-wrap img {
    width: 100%;
  }
  
  .autoCAD-subCategory {
    display: flex;
    justify-content: center;
  }
  .autoCAD-subCategory > div {
    margin: 1rem;
  }
  .autoCAD-desc {
    padding: 1rem;
    margin-top: 1.5rem;
    font-size: large;
  }
 
  .Cover{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: larger;
  }
  .column {
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  
 

.training {
    width: 100%;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: 100%;
    text-align: center;
     justify-content: center;
    line-height: normal;
    vertical-align: middle;
    /* height: 150px; */
    /* align-items: center; */
    padding: 34px;
    grid-gap: 3rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.training .left {
    text-align: center;
    margin: auto;
    padding: 1rem 1rem;
    max-width: 600px;
    font-size: 11px;
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.984);
}



.btn{
    max-width: 466px;
    border-bottom: 1px dotted rgba(247, 242, 242, 0.677);
    white-space: pre;
}

.btn:before{
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 140px;
    background-color: rgba(0, 0, 0, 0.471);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
    left: 0;
    top: 110%;
}

.btn:hover:before{
    opacity: 1;
    visibility: visible;
    font-size: 12px;
    color: rgb(227, 109, 66);
    text-transform: uppercase;
}

.training .left p {
    margin: 1.2rem 0;
}

.training .right {
    width: 100%;
}

.right .img-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .img {
    max-width: 45%;
    border: 1px solid #333;
}
.right .img1 {
    max-width: 45%;
    border: 1px solid #333;
}
.right .img2 {
    max-width: 45%;
    border: 1px solid #333;
}
.right .img3 {
    max-width: 45%;
    border: 1px solid #333;
}

.right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.right .bottom {
    grid-column: 4/ -1; 
    grid-row: 1;
    z-index: 5;
}
.left .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.left .bottom {
    grid-column: 4/ -1; 
    grid-row: 1;
    z-index: 5;
}

@media screen and (max-width: 1140px) {
    .training {
        grid-template-columns: 1fr;
    }

    .training .img {
        max-width: 80%;
    }

}






