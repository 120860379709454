.blog-wrap {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }
  
  .blog-goBack {
    text-decoration: none;
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
    margin-bottom: 2rem;
    display: block;
    cursor: pointer;
    font-size: large;
  }

  .blog-goBack:hover {
   color: lightblue;
  }

  .blog-wrap header {
    text-align: center;
  }
  
  .blog-date {
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
  }
  
  .blog-wrap img {
    width: 100%;
  }
  
  .blog-subCategory {
    display: flex;
    justify-content: center;
  }
  .blog-subCategory > div {
    margin: 1rem;
  }
  .blog-desc {
    padding: 1rem;
    margin-top: 1.5rem;
    font-size: large;
  }
 
  .Cover{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    font-size: larger;
  }
  .column {
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  
 
