label {
    color: #eee;
}

form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
    max-width: 600px;
}

.Logo{
    
    /*inline-size: -webkit-fill-available;*/
    width: 30%;
    height: auto;
    font-size: 1.2rem;
    opacity: 0.5;
}

.h3 {
  text-decoration: underline;
}

form label {
    margin-bottom: .5rem;
}

form input, textarea {
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    font-family: 'Outfit', sans-serif;
    background: rgba(255,255,255,.1);
    border-color: rgba(255,255,255,.1);
    color: #f4f4f4;
}

.container {
    position: relative;
    text-align: center;
    color: white;
  }
  
  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
  
  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  
  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }