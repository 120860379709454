.training {
    width: 100%;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: 100%;
    text-align: center;
     justify-content: center;
    line-height: normal;
    vertical-align: middle;
    /* height: 150px; */
    /* align-items: center; */
    padding: 34px;
    grid-gap: 3rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.training .left {
    text-align: center;
    margin: auto;
    padding: 1rem 1rem;
    max-width: 600px;
    font-size: 11px;
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.984);
}



.btn{
    max-width: 466px;
    border-bottom: 1px dotted rgba(247, 242, 242, 0.677);
    white-space: pre;
}

.btn:before{
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 140px;
    background-color: rgba(0, 0, 0, 0.471);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
    left: 0;
    top: 110%;
}

.btn:hover:before{
    opacity: 1;
    visibility: visible;
    font-size: 12px;
    color: rgb(227, 109, 66);
    text-transform: uppercase;
}

.training .left p {
    margin: 1.2rem 0;
}

.training .right {
    width: 100%;
}

.right .img-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .img {
    max-width: 45%;
    border: 1px solid #333;
}
.right .img1 {
    max-width: 45%;
    border: 1px solid #333;
}
.right .img2 {
    max-width: 45%;
    border: 1px solid #333;
}
.right .img3 {
    max-width: 45%;
    border: 1px solid #333;
}

.right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.right .bottom {
    grid-column: 4/ -1; 
    grid-row: 1;
    z-index: 5;
}
.left .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.left .bottom {
    grid-column: 4/ -1; 
    grid-row: 1;
    z-index: 5;
}

@media screen and (max-width: 1140px) {
    .training {
        grid-template-columns: 1fr;
    }

    .training .img {
        max-width: 80%;
    }

}





