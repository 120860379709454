.blogList-wrap {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 3rem;
    font: small-caption;
    justify-content: center;
    line-height: normal;
    vertical-align: middle;
    /* height: 150px; */
    /* align-items: center; */
    /*padding: 34px;*/
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align:center;
}

@media(max-width:768px){
    .blogList-wrap {
        grid-template-columns: repeat(2,1fr);
    }
}

@media(max-width:600px){
    .blogList-wrap {
        grid-template-columns: repeat(1,1fr);
    }
}